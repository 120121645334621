import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { sport: String };

  initialize() {
    this.mapMoved = false;
    this.debounceTimer = null;
    this.markers = L.layerGroup();
    this.circleLayerGroup = L.layerGroup();
  }

  connect() {
    this.map = window.map;
    this.sport = this.element.getAttribute("data-map-spots-loader-sport-value");

    this.map.on("moveend", () => {
      this.handleMapChange();
    });

    this.map.on("zoomend", () => {
      this.handleMapChange();
    });

    // Ajouter les layer groups à la carte
    this.markers.addTo(this.map);
    this.circleLayerGroup.addTo(this.map);
  }

  handleMapChange() {
    clearTimeout(this.debounceTimer);

    this.debounceTimer = setTimeout(() => {
      const bounds = this.map.getBounds();
      const data = {
        north: bounds.getNorth(),
        south: bounds.getSouth(),
        east: bounds.getEast(),
        west: bounds.getWest(),
        zoom: this.map.getZoom(),
        sport: this.sport
      };
      this.fetchSpots(data);
    }, 500);
  }

  fetchSpots(bounds) {
    let url = "/update_population";
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CSRF-Token": this.getMetaValue("csrf-token")
      },
      body: JSON.stringify(bounds)
    })
    .then(response => response.json())
    .then(data => {
      this.clearMarkers();
      this.displaySpots(data.spots);
      if (data.hidden_spots) {
        this.displayHiddenSpots(data.hidden_spots);
      }
    })
    .catch(error => {
      console.error("Error fetching spots:", error);
    });
  }

  clearMarkers() {
    this.markers.clearLayers();
    this.circleLayerGroup.clearLayers();
  }

  markerExistsAt(latitude, longitude) {
    let exists = false;
    this.map.eachLayer((layer) => {
      if (layer instanceof L.Marker) {
        const markerLatLng = layer.getLatLng();
        if (markerLatLng.lat === latitude && markerLatLng.lng === longitude) {
          exists = true;
        }
      }
    });
    return exists;
  }

  displaySpots(spots) {
    if (!spots) return;

    spots.forEach((spot) => {
      const { latitude, longitude, label, slug, sport } = spot;

      // Vérifier tous les marqueurs existants
      if (!this.markerExistsAt(latitude, longitude)) {
        const normalizedSport = sport.toLowerCase().replace('_', '-');

        // Récupérer les URLs des icônes depuis les meta tags
        const kitePinUrl = document.querySelector('meta[name="kite-pin-url"]').getAttribute("content");
        const paraglidingPinUrl = document.querySelector('meta[name="paragliding-pin-url"]').getAttribute("content");
        const surfPinUrl = document.querySelector('meta[name="surf-pin-url"]').getAttribute("content");
        const wingFoilPinUrl = document.querySelector('meta[name="wing-foil-pin-url"]').getAttribute("content");
        const windsurfPinUrl = document.querySelector('meta[name="windsurf-pin-url"]').getAttribute("content");
        const surfFoilPinUrl = document.querySelector('meta[name="surf-foil-pin-url"]').getAttribute("content");

        // Définir les configurations d'icônes par sport
        const sportIcons = {
          kite: L.icon({
            iconUrl: kitePinUrl,
            iconSize: [40, 48],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
          }),
          paragliding: L.icon({
            iconUrl: paraglidingPinUrl,
            iconSize: [40, 48],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
          }),
          surf: L.icon({
            iconUrl: surfPinUrl,
            iconSize: [40, 48],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
          }),
          "wing-foil": L.icon({
            iconUrl: wingFoilPinUrl,
            iconSize: [40, 48],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
          }),
          "kite-foil": L.icon({
            iconUrl: kitePinUrl,
            iconSize: [40, 48],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
          }),
          windsurf: L.icon({
            iconUrl: windsurfPinUrl,
            iconSize: [40, 48],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
          }),
          "surf-foil": L.icon({
            iconUrl: surfFoilPinUrl,
            iconSize: [40, 48],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
          }),
          other: L.icon({
            iconUrl: surfFoilPinUrl,
            iconSize: [40, 48],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
          }),
        };

        const marker = L.marker([latitude, longitude], {
          icon: sportIcons[normalizedSport]
        });

        const popupContent = `
          <a href="#" class="text-decoration-none" data-controller="spot-forecast" data-action="click->spot-forecast#open" data-spot-forecast-url-value="/spots/${slug}/details">
            <div class="d-flex flex-column align-items-center justify-content-center p-0">
              <div class="mb-0 text-center text-muted"> <span class="font-weight-bold" style="font-size:1rem;">${label}</span> <br><span class="font-italic">${sport}</span></div>
              <button type="button" class="btn btn-outline-success btn-sm mt-2 px-3 rounded-pill">Forecast</button>
            </div>
          </a>`;
        marker.bindPopup(popupContent);

        this.markers.addLayer(marker);
      }
    });
  }

  displayHiddenSpots(spots) {
    if (!spots) return;

    const hiddenIconUrl = document.querySelector('meta[name="marker-hidden-url"]').getAttribute("content");
    const isUserLoggedIn = document.body.getAttribute('data-user-logged') === 'true';

    spots.forEach((spot) => {
      const { latitude, longitude } = spot;

      const marker = L.marker([latitude, longitude], {
        icon: L.icon({
          iconUrl: hiddenIconUrl,
          iconSize: [40, 48],
          iconAnchor: [12, 41],
          popupAnchor: [1, -34]
        })
      });

      let popupContent;
      if (!isUserLoggedIn) {
        popupContent = `
          <a href="/users/sign_in" class="text-decoration-none">
            <div class="d-flex flex-column align-items-center justify-content-center p-0">
              <div class="mb-0 text-center text-muted">Some spots are accessible <br> only to Premium members now!</div>
              <button type="button" class="btn btn-success btn-sm mt-2 px-3">
                <span class="mx-2">🔒</span>
                <span class="font-weight-bold p-1 m-1">Login</span>
              </button>
            </div>
          </a>`;
      } else {
        popupContent = `
          <div data-controller="modal">
            <div data-action="click->modal#loadContent"
                 data-url="/information/become_member"
                 class="text-decoration-none">
              <div class="d-flex flex-column align-items-center justify-content-center p-0">
                <div class="mb-0 text-center text-muted">Unlock all spots & more</div>
                <button type="button" class="btn premium-button mt-2 px-3">
                  <span class="mx-2">🚀</span>
                  <span class="font-weight-bold text-white">Become Premium</span>
                </button>
              </div>
            </div>
            <dialog data-modal-target="dialog" class="dialog"></dialog>
          </div>`;
      }

      marker.bindPopup(popupContent);
      this.circleLayerGroup.addLayer(marker);
    });
  }

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`);
    return element.getAttribute("content");
  }
}
