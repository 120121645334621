import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { guidesListToDisplay: Array };

  initialize() {
    this.circleLayerGroup = L.layerGroup();
  }

  connect() {
    this.map = window.map;
    this.displayGuides();
  }

  displayGuides() {
    this.circleLayerGroup.clearLayers();

    // Récupérer l'URL de l'icône depuis les meta tags
    const guidePinUrl = document.querySelector('meta[name="guide-pin-url"]').getAttribute("content");

    // Configuration de l'icône
    const guideIcon = L.icon({
      iconUrl: guidePinUrl,
      iconSize: [40, 48],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34]
    });

    // Création des marqueurs pour chaque guide
    this.guidesListToDisplayValue.forEach((guide) => {
      const marker = L.marker([guide.latitude, guide.longitude], {
        icon: guideIcon
      });

      marker.bindPopup(this.createPopupContent(guide));
      this.circleLayerGroup.addLayer(marker);
    });

    // Ajout du groupe de marqueurs à la carte
    this.circleLayerGroup.addTo(this.map);
  }

  createPopupContent(guide) {
    return `
      <a href="/guides/${guide.slug}" target="_blank" class="text-decoration-none">
        <div class="d-flex flex-column align-items-center justify-content-center p-0">
          <div class="mb-0 text-center text-muted">
            <span class="font-weight-bold" style="font-size:1rem;">${guide.name}</span>
            <br>
            <span class="font-italic">${guide.sport}</span>
          </div>
          <div class="d-flex gap-2 mt-2">
            <button type="button" class="btn btn-outline-primary btn-sm px-3 rounded-pill">
              Guide
            </button>
          </div>
        </div>
      </a>`;
  }

  disconnect() {
    if (this.map && this.circleLayerGroup) {
      this.map.removeLayer(this.circleLayerGroup);
    }
  }
}
